<template>
  <div class="aspect-video">
    <Transition name="fade" mode="out-in" @after-leave="onAfterLeave">
      <div
        v-if="transitionState == 'default'"
        :style="posterStyle"
        :class="[aspectRatio]"
        class="bg-center bg-no-repeat bg-cover relative rounded-t-2xl"
      >
        <div class="w-full flex gap-x-1 items-center pt-2 pe-2 justify-end">
          <UiTagLive v-if="useIsLive(media)"></UiTagLive>
          <UiTagPlus v-if="media.showPlusLabel"></UiTagPlus>
        </div>
      </div>
      <div v-else class="relative w-full rounded-t-lg aspect-video">
        <div
          class="absolute top-0 start-0 w-full h-full gradient vertical"
        ></div>
        <div
          class="absolute z-10 top-0 start-0 w-full h-full p-4 flex gap-x-2 items-end justify-end"
        >
          <ButtonsIcon
            class="variant-secondary !w-9 !h-9"
            icon-name="IconTimesWhite"
            @click.stop="onStopTrailer"
            size="20"
          ></ButtonsIcon>
          <ButtonsIcon
            size="20"
            :icon-name="volumeIconName"
            class="variant-secondary !w-9 !h-9"
            @click.stop="store.toggleMute()"
          ></ButtonsIcon>
        </div>
        <div class="relative w-full h-full">
          <video
            id="trailerEl"
            autoplay
            playsinline
            class="rounded-t-lg w-full h-full"
          ></video>
          <div
            :style="{ width: progress }"
            class="absolute bottom-0 h-[6px] bg-brand-red transition-all ease-linear left-0"
          ></div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { PLAYERS } from "@/constants";
import { usePlayerStoreWithID } from "@/stores/playerWithId";

const { fetchTrailer } = useFetchAsset();

const props = defineProps({
  media: Object,
  floaterImage: String,
  isFullyExpanded: Boolean,
  transitionState: String,
  aspectRatio: {
    type: String,
    default: () => "aspect-video",
  },
});

const emit = defineEmits("onStopTrailer");

const transitionState = ref("default");
const trailer = ref();
const trailerTimeout = ref();
const hideTimeout = ref();
const showHoveredContent = ref();

const progress = ref("0%");

const store = usePlayerStoreWithID(PLAYERS.FLOATER_PLAYER)();

const onAfterLeave = async () => {
  if (props.transitionState == "default") {
    await store.player.destroy();
    return;
  }

  await loadPlayer();
};

onMounted(async () => {
  console.log("Trailer duration:", props?.media?.trailerDuration ?? "❌");

  if (props?.media?.trailerDuration > 0) {
    await getTrailer();
    trailerTimeout.value = setTimeout(() => {
      trailerTimeout.value && clearTimeout(trailerTimeout.value);
      transitionState.value = "player";
    }, 2000);
  }
});

/**
 * determines the image to be used for the floater image (hero)
 * if the floater image has a value (!null || !undefined),
 * always use that and apply bgSize: cover
 *
 * if it's empty, handle it below
 * if the media is a live program and has a channel object,
 * use the channel logo and apply bgSize: contain
 */
const posterStyle = computed(() => {
  const style = useFallbackImage().style;
  if (props.floaterImage) {
    Object.assign(style, {
      backgroundImage: `url('${props.floaterImage}')`,
      backgroundSize: "cover",
    });
  } else {
    if (
      props.media.type == "LiveProgram" &&
      Object.keys(props.media).includes("channel")
    ) {
      const images = useMediaAssets(props.media.channel);
      Object.assign(style, {
        backgroundImage: `url('${images.logo}')`,
        backgroundSize: "contain",
      });
    }
  }

  return style;
});

onBeforeUnmount(() => {
  trailerTimeout.value && clearTimeout(trailerTimeout.value);
  hideTimeout.value && clearTimeout(hideTimeout.value);
  onStopTrailer();
});

const getTrailer = async () => {
  const response = await fetchTrailer(props?.media);
  if (response) {
    trailer.value = response;
  }
};

async function loadPlayer() {
  store.initialize("trailerEl");
  await store.loadMedia(
    {
      url: trailer.value.attachmentPlayingInfo.url,
    },
    {},
    {},
    {
      sesContentType: "Trailer",
      sesSessionType: "Trailer",
    }
  );

  registerEvents();

  hideUi();
}

const registerEvents = () => {
  watch(
    () => store.playbackState,
    (newVal) => {
      if (newVal == "ended") {
        onStopTrailer();
      }
    }
  );

  watch(
    () => store.currentTime,
    () => {
      const perc = Math.min(store.indicatorPosition * 100, 100);
      progress.value = `${perc}%`;
    }
  );
};

function hideUi() {
  clearTimeout(hideTimeout.value); // Clear any existing timeout.
  hideTimeout.value = setTimeout(() => {
    hideTimeout.value && clearTimeout(hideTimeout.value);
    showHoveredContent.value = false;
  }, 3000);
}

const volumeIconName = computed(() =>
  store.isMuted ? "IconVolumeOff" : "IconVolumeHigh"
);

const onStopTrailer = async () => {
  transitionState.value = "default";
  await store.player?.reset();
  await store.player?.destroy();
};
</script>

<script>
export default { name: "FloaterHero" };
</script>

<style lang="scss" scoped></style>
