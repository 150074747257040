<template>
  <div class="p-4 flex flex-col gap-y-4 rounded-b-2xl">
    <div class="flex flex-col">
      <h2
        class="text-title-big-bold text-white opacity-[0.87] cursor-pointer"
        @click="onInfoClick"
      >
        {{ label }}
      </h2>

      <OverlayDescriptionTags
        :media="media"
        opacity-class="opacity-50"
      ></OverlayDescriptionTags>

      <div class="p-0.5">
        <p
          class="line-clamp-3 text-title-small text-white opacity-[0.87] tracking-[0.2px]"
        >
          {{ description }}
        </p>
      </div>
    </div>
    <div class="flex justify-between items-center">
      <div class="flex gap-x-2">
        <OverlayCtaSection
          :media="media"
          :show-label="media.type != 'Movie'"
          :in-floater="true"
          :gtmTitleLabel="gtmTitleLabel"
          id="floater__cta"
        ></OverlayCtaSection>
      </div>
      <div
        v-show="false"
        class="flex gap-x-2"
      >
        <span
          class="rounded-sm border border-white/10 bg-white/5 px-2 py-1 font-Abu text-label-medium text-white opacity-[0.87]"
        >
          {{ $t("16+") }}
        </span>
        <span
          class="rounded-sm border border-white/10 bg-white/5 px-2 py-1 font-Abu text-label-medium text-white opacity-[0.87]"
        >
          {{ $t("HD") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useSessionStore } from "@/stores/session";
const sessionStore = useSessionStore();

const props = defineProps({
  media: {
    type: Object,
    required: true,
  },
  gtmTitleLabel: String,
});

const emit = defineEmits("on-dismiss");

const label = computed(() => useFindLabel(props.media));

const description = computed(() => {
  if (!props.media) return null;
  switch (props.media.type) {
    case "Season":
      const { tvShowSeason, tvShowSeasons } = props.media;
      if (tvShowSeason) {
        return tvShowSeason.description;
      }

      if (Array.isArray(tvShowSeasons) && tvShowSeasons.length > 0) {
        return tvShowSeasons[0].description;
      }

      return props.media.description;
    case "Series":
      return props.media.tvShowSeries?.description || props.media.description;

    case "Movie":
      return (props.media.aggregatedVideo || props.media).description;
    default:
      return props.media.description;
  }
});

const onInfoClick = () => {
  emit("on-dismiss", "showDetail");
};
</script>

<style lang="scss" scoped></style>
