<template>
  <div
    :id="elementId"
    :class="{ 'z-[70]': elementIsHovered }"
    class="floater-v4 relative flex items-center justify-center"
  >
    <slot name="triggerContent">
      <div
        :style="{ backgroundImage: `url('${image}')` }"
        class="h-full w-full rounded-lg aspect-video bg-center bg-no-repeat bg-cover"
      ></div>
    </slot>
    <Transition
      name="fade-fast"
      mode="out-in"
    >
      <div
        v-if="elementIsHovered"
        :class="topPosition"
        class="z-50 absolute rounded-2xl shadow-lg bg-brand-red-surface w-[381px]"
      >
        <FloaterV4Hero
          :id="`${elementId}__tooltip__thumbnail`"
          :floater-image="floaterImage"
          :aspect-ratio="aspectRatio"
          :media="media"
        ></FloaterV4Hero>
        <FloaterV4Info
          :media="media"
          :gtmTitleLabel="gtmTitleLabel"
        ></FloaterV4Info>
      </div>
    </Transition>
  </div>
</template>

<script setup>
const props = defineProps({
  elementId: {
    type: String,
    required: true,
  },
  thumbClass: String,

  isHovered: {
    type: [String, Boolean],
    default: () => "",
  },
  floaterImage: {
    type: String,
  },
  media: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: () => false,
  },
  topPosition: String,
  aspectRatio: String,
  gtmTitleLabel: String,
});

const elementIsHovered = computed(() => {
  if (props.disabled) return false;

  return props.elementId == props.isHovered;
});

const element = computed(() => {
  return document.getElementById(props.elementId);
});
</script>

<style lang="scss" scoped></style>
